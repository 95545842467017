<template>
  <bg-modal
    :value="isOpen"
    title="Yakin menghapus biaya tambahan penyewa?"
    description="Biaya tambahan penyewa yang dihapus tidak dapat dikembalikan."
    desktop-size="sm"
    :closable="false"
    :close-on-click-backdrop="false"
    data-testid="confirm-delete-additional-cost-modal"
    @close="closeModal"
  >
    <template #footer>
      <div class="flex justify-end">
        <bg-button
          :loading="isLoading"
          variant="primary"
          class="mr-16"
          :data-testid="`confirm-delete-additional-cost-${costId}`"
          @click="deleteTransaksi"
        >
          Hapus
        </bg-button>
        <bg-button :loading="isLoading" @click="closeModal">Batal</bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
import { BgModal, BgButton } from 'bangul-vue';
import detailTransferApi from '@admin_endpoints/detail-transfer';

export default {
  name: 'DeleteInvoiceManualAdditionalCostModal',

  components: {
    BgModal,
    BgButton,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    costId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    approvalId() {
      return this.$route.params.approval_id;
    },
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
    },

    async deleteTransaksi() {
      if (this.costId !== null) {
        this.isLoading = true;

        try {
          await detailTransferApi.deleteInvoiceManualAdditionalCost(
            this.approvalId,
            this.costId
          );

          this.$emit('action-success-delete');
          this.$toast.show('Biaya berhasil dihapus');
        } catch (error) {
          const errorMessage =
            error?.response?.data.issue?.message ||
            'Gagal saat menghapus biaya.';

          this.$toast.show(errorMessage);
        } finally {
          this.isLoading = false;
          this.closeModal();
        }
      }
    },
  },
};
</script>
